<template>
    <main class="p-workshop">
        <section class="intro" v-if="content">
            <h1 class="intro__title">{{ content.title }}</h1>
            <div class="intro__desc">{{ content.introDescription }}</div>
        </section>

        <section v-if="content">
            <div class="image-container">
                <img v-if="firstFullwidthImage" :src="firstFullwidthImage" alt="" class="fullwidth" />
            </div>

            <div class="images">
                <div class="image" v-for="(image, index) in images" :key="index">
                    <img :src="image" alt="" />
                </div>
            </div>

            <div class="image-container">
                <img v-if="lastFullwidthImage" :src="lastFullwidthImage" alt="" class="fullwidth" />
            </div>
        </section>

        <Cta :type="'big'" :ctaText="$t('global.ctaProjects')" :ctaUrl="$t('nav.projects.uri')" />
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Cta from '@/components/Cta';

export default {
    name: 'Workshop',

    components: {
        Cta
    },

    data() {
        return {
            content: null,
        }
    },

    computed: {
        firstFullwidthImage() {
            return this.content ? this.content.images[0] : null;
        },

        lastFullwidthImage() {
            return this.content ? this.content.images[this.content.images.length - 1] : null;
        },

        images() {
            // Without the two fullwidth ones
            return this.content ? this.content.images.slice(1, this.content.images.length - 1) : null;
        }
    },

    created() {
        this.$craft.getContentBySlug(this.$t('nav.workshop.slug'))
            .then(res => {
                this.content = res.data;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                ScrollTrigger.refresh(true);
                this.initAnimations();
            })
            .then(() => {
                this.$store.commit('SET_OTHER_LOCALES_URLS', this.content.otherLocalesUrls);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.utils.toArray('.image-container img').forEach((fullwidthImage) => {
                gsap.fromTo(fullwidthImage, {
                    y: -100,
                }, {
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: fullwidthImage,
                        start: 'top center',
                        end: '+=100%',
                        scrub: 0.8,
                    },
                });
            });

            var imgCount = 1;
            gsap.utils.toArray('.images .image img').forEach((image) => {
                // Images arrives from right to left or left to right based on their order
                let xDirection = -1;
                if (imgCount % 2) {
                    xDirection = 1;
                }

                gsap.from(image, {
                    opacity: 0,
                    x: 200 * xDirection,
                    duration: 1,
                    ease: 'Power1.inOut',
                    scrollTrigger: {
                        trigger: image,
                        start: 'top 40%',
                        end: '+=100%',
                    },
                });

                imgCount++;
            });

            ScrollTrigger.refresh(true);
        }
    }
}
</script>